import { createApp } from 'vue'
import App from './App.vue'
import { initFacebookSdk } from './services/FacebookSDK'

// wait for facebook sdk to start app
initFacebookSdk().then(startApp());

function startApp() {
  createApp(App).mount('#app');
}
