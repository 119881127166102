<template>
  <InstagramAccount/>
</template>

<script>
import InstagramAccount from '@/components/InstagramAccount.vue'

export default {
  name: 'App',
  components: {
    InstagramAccount
  }
}
</script>

<style>
body {
  margin: 0;
  overflow: hidden;
  padding: 10vh 5vw 20vh;
  height: calc(100vh - 10vh - 20vh);
  width: calc(100vw - 10vw);
  background: linear-gradient(to right top, rgb(253, 141, 50), rgb(163, 7, 186));
  background-size: cover;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
