<template>
  <div class="display_info">
    <!--    <img class="profile_picture" :src="profilePicture"/>-->
    <p
      class="follow_goal"
      :class="[goalNumber == nextFollowersGoal ? 'selected' : '']"
      v-for="(goal, goalNumber, index) in followersGoals"
      :key="index"
    >
      {{ goalNumber }} followers : {{ goal }}
    </p>
  </div>
  <div class="profile_info">
    <div class="qr_code">
      <img src="@/assets/qrcode-stephane.png" alt="QR code"/>
    </div>
    <div class="profile_data">
      <p class="display_name">FOLLOW US</p>
      <p class="next_goal">Next goal : {{ nextFollowersGoal }} Followers</p>
      <ProgressBar
        :currentNumber="Number(followersCount)"
        :maxNumber="Number(nextFollowersGoal)"
      />
    </div>
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar.vue";

export default {
  name: "InstagramAccount",
  components: {
    ProgressBar,
  },
  data() {
    return {
      instagramUserID: "17841457207260637", // TODO : move to app settings
      name: "The Name", // TODO : change for waiting values before the API fetch data, or for saved data
      followersCount: 0, // TODO : change for waiting values before the API fetch data, or for saved data
      profilePicture: "https://randomuser.me/api/portraits/women/2.jpg", // TODO : change for waiting values before the API fetch data, or for saved data
      followersGoals: {
        1500: "Mixtape release",
        2000: "All-night old-school DJ Party",
        2500: "Old-school club party",
        5000: "Hanami cruise party",
      },
      timer: null,
    };
  },
  methods: {
    async getInstagramDataFromID(instagramUserID, facebookAccessToken) {
      let instagramData = {};
      if (instagramUserID && facebookAccessToken) {
        console.log("Getting instagram data with token " + facebookAccessToken); // Todo replace for debug log
        const instagramApiURL =
          "https://graph.facebook.com/v16.0/" +
          instagramUserID +
          "?fields=id%2Cname%2Cusername%2Cfollowers_count%2Cprofile_picture_url"; // TODO store facebook API somewhere else
        instagramData = await fetch(
          instagramApiURL + "&access_token=" + facebookAccessToken
        ).catch((error) => {
          console.error("Error when fetching data : " + error);
        });
        return await instagramData.json();
      } else {
        console.log("missing instagramUserID or facebookAccessToken"); // Todo replace for warning log
        return instagramData;
      }
    },
    async fillPageDataFromID(instagramUserID) {
      let instagramData = {};
      let facebookAccessToken = localStorage.FacebookAccessToken;
      instagramData = await this.getInstagramDataFromID(
        instagramUserID,
        facebookAccessToken
      );
      this.name = instagramData.name;
      this.followersCount = instagramData.followers_count;
      this.profilePicture = instagramData.profile_picture_url;
    },
  },
  computed: {
    nextFollowersGoal() {
      let nextFollowGoal = 0;
      for (let goalNumber of Object.keys(this.followersGoals)) {
        // TODO handle lists that are not sorted
        // set the nextFollowGoal to the first goal bigger than the current count of followers
        if (goalNumber > this.followersCount) {
          nextFollowGoal = goalNumber;
          break;
        }
      }
      return nextFollowGoal;
    },
  },
  mounted() {
    if (this.instagramUserID && localStorage.FacebookAccessToken) {// TODO login if the Facebook token is not found
      this.fillPageDataFromID(this.instagramUserID);
      this.timer = setInterval(() => {
        if (this.instagramUserID && localStorage.FacebookAccessToken) {
          this.fillPageDataFromID(this.instagramUserID);
        } else {
          clearInterval(this.timer); // TODO restart the time if the token is found again
        }
      }, 5000);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}

.display_info {
  p:not(.selected) {
    font-size: 4vh;
  }
  .selected {
    font-size: 5vh;
    font-weight: bold;
  }
}

.profile_picture {
  max-height: 25vh;
  max-width: 30vw;
  margin-bottom: 8vh;
}
.profile_info {
  display: flex;
  padding: 0 10% 2vh;
  justify-content: space-around;
  max-height: 35vh;
}
.qr_code {
  // TODO Responsive wrong on width in some cases
  max-height: 100%;
  width: auto;
  margin: 0 5vw;
}
.qr_code img {
  min-height: 100%;
  max-height: 100%;
  width: auto;
  max-width: 100%;
}
.profile_data {
  flex-grow: 1;
  margin: 0 5vw;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .display_name {
    font-weight: bold;
    font-size: 4vh;
  }

  .next_goal {
    font-size: 4.5vh;
  }
}
</style>
