// Service to connect to Facebook and manage the API
// Code taken from  https://codesandbox.io/s/l9tkc?file=/src/_helpers/init-facebook-sdk.js:823-833
// TODO implement or clean commented code

// import { BehaviorSubject } from 'rxjs';

const facebookAppId = "1230098280935548"; // TODO  move to env
// const accountSubject = new BehaviorSubject(null);
// const baseUrl = "https://graph.facebook.com/v16.0/";
let FB = {};

export function initFacebookSdk() {
  return new Promise(resolve => {
    // wait for facebook sdk to initialize before starting the vue app
    window.fbAsyncInit = function () {
      FB = window.FB;
      console.log("init FaceBookSdk"); // TODO move to debug
      FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v8.0'
      });

      // auto authenticate with the api if already logged in with facebook
      FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          // apiAuthenticate(authResponse.accessToken).then(resolve);
          console.log("Already logged in : " + authResponse.accessToken); // TODO move to debug
          localStorage.setItem("FacebookAccessToken", authResponse.accessToken);
          resolve();
        } else {
          login().then(resolve);
        }
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  });
}

export async function login() {
  // login with facebook then authenticate with the API to get a JWT auth token
  console.log("login to");
  const { authResponse } = await new Promise(FB.login).catch((error) => {
    console.error('Error when login : ' + error);
  });
  if (!authResponse) return;

  localStorage.setItem("FacebookAccessToken", authResponse);
  // const account = await apiAuthenticate(authResponse.accessToken);

  return authResponse.accessToken;
}

// async function apiAuthenticate(accessToken) {
//   // authenticate with the api using a facebook access token,
//   // on success the api returns an account object with a JWT auth token
//   const response = await fetch(`${baseUrl}/me?access_token=${accessToken}`, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({ accessToken }),
//   });
//   const account = response.data;
//   accountSubject.next(account);
//   startAuthenticateTimer();
//   return account;
// }
//
// function logout() {
//   // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
//   FB.api('/me/permissions', 'delete', null, () => FB.logout());
//   stopAuthenticateTimer();
//   accountSubject.next(null);
// }
//
// let authenticateTimeout;
//
// function startAuthenticateTimer() {
//   // parse json object from base64 encoded jwt token
//   const jwtToken = JSON.parse(atob(accountSubject.value.token.split('.')[1]));
//
//   // set a timeout to re-authenticate with the api one minute before the token expires
//   const expires = new Date(jwtToken.exp * 1000);
//   const timeout = expires.getTime() - Date.now() - (60 * 1000);
//   const { accessToken } = FB.getAuthResponse();
//   authenticateTimeout = setTimeout(() => apiAuthenticate(accessToken), timeout);
// }
//
// function stopAuthenticateTimer() {
//   // cancel timer for re-authenticating with the api
//   clearTimeout(authenticateTimeout);
// }
