<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    style="display: none"
  >
    <symbol id="wave">
      <g transform="rotate(90) translate(0, -19)">
        <path
          d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"
        ></path>
        <path
          d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"
        ></path>
        <path
          d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"
        ></path>
        <path
          d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"
        ></path>
      </g>
    </symbol>
  </svg>
  <div class="newFollowersIconsContainer">
    <img
        src="@/assets/follow.png"
        v-for="(icon, iconName, index) in followerIcons"
        :key="index"
        :alt="iconName"
        :style="{
        'top':((icon.top + icon.bottom) / 2 ) + 'px',
        'left': ((icon.left + icon.right) / 2 ) + 'px',
        'height': icon.height + 'px',
        'animation-duration': animationSpeed + 's'
      }"
    />
  </div>
  <div class="box">
    <div id="water" ref="water" class="water">
      <svg viewBox="0 0 560 560" class="water_wave water_wave_back">
        <use xlink:href="#wave"></use>
      </svg>
      <svg viewBox="0 0 560 560" class="water_wave water_wave_front">
        <use xlink:href="#wave"></use>
      </svg>
      <div class="followers_count">
        <p ref="followersCountText">{{ tempNumberToDisplay || currentNumber }}</p>
      </div>
    </div>
  </div>
  <ConfettiModal v-for="icon in followerIcons" :key="icon"/>
</template>

<script>
import ConfettiModal from "@/components/ConfettiModal";

export default {
  name: "ProgressBar",
  data() {
    return {
      followerIcons: {},
      tempNumberToDisplay: null,
      animationSpeed: 2
    };
  },
  props: {
    currentNumber: Number,
    maxNumber: Number,
  },
  components: {
    ConfettiModal
  },
  methods: {
    fillProgressBar() { // This function fills the progress bar with the fluid
      const water = this.$refs.water;
      const progressionInPercent = (this.currentNumber * 100) / this.maxNumber;
      water.style.transform =
        "translate(-" + (100 - progressionInPercent) + "%, 0)";
      if (progressionInPercent >= 100) {
        water.style.transform = "translate(0, 0)";
      }
    },
    addFollower(previousFollowersCount) { // This function triggers the animation for new followers
      const name = "icon" + (Object.keys(this.followerIcons).length + 1);
      this.tempNumberToDisplay = previousFollowersCount;
      this.followerIcons[name] = this.$refs.followersCountText.getBoundingClientRect();
      setTimeout(() => { // delete the icon when the animation is finished
        delete this.followerIcons[name];
        this.tempNumberToDisplay = null;
      }, this.animationSpeed*1000);
    }
  },
  watch: {
    currentNumber: function (newFollowersCount, previousFollowersCount) {
      console.debug(`Followers count changed from ${previousFollowersCount} to ${newFollowersCount}`); // TODO move to debug
      if (newFollowersCount > previousFollowersCount && previousFollowersCount != 0) {
        this.addFollower(previousFollowersCount);
      }
    }
  },
  mounted() {
    this.fillProgressBar();
  },
  updated() {
    this.fillProgressBar();
  },
};
</script>

<style lang="scss" scoped>
// Imported from Codepen
*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}
$bgColor: #f9f9f9;
$boxColor: #ffffff;
$waterFColor: #fc6681;
$waterBColor: #fb9cad;

body {
  background: $bgColor;
  font: 14px/1 "Gilroy";
  -webkit-font-smoothing: antialiased;
}

.box {
  height: 40%;
  width: 100%;
  position: relative;
  background: $boxColor;
  border-radius: 12px;
  border: 1px solid black;
  overflow: hidden;
  z-index: 1;

  .followers_count {
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 10;
    position: relative;
    align-items: center;
    padding: 5%;
    font-weight: bold;
    justify-content: flex-end;
    p {
      margin: 0;
      color: white;
      text-align: left;
    }
  }

  .water {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transform: translate(-100%, 0);
    background: $waterFColor;
    transition: all 0.3s;
    &_wave {
      height: 200%;
      position: absolute;
      left: 100%;
      &_back {
        top: 0;
        fill: $waterBColor;
        animation: wave-back 1.4s infinite linear;
      }
      &_front {
        bottom: 0;
        fill: $waterFColor;
        margin-left: -1px;
        animation: wave-front 0.7s infinite linear;
      }
    }
  }
}
@keyframes wave-front {
  100% {
    transform: translate(0, 50%);
  }
}

@keyframes wave-back {
  100% {
    transform: translate(0, -50%);
  }
}

//Animation for the new followers
.newFollowersIconsContainer {
  z-index: 11; // This is for the icon to be in front of the confetti
  img {
    position: fixed;
    transform: translate(-50%, -50%);
    animation-name: addFollower;
    animation-timing-function: ease;
  }
}

@keyframes addFollower {
  0% {top: 120%; left: 50%; height: 0%;}
  14% {top: 50%; left: 50%; height: 100%;}
  23% {top: 50%; left: 50%; height: 78%;}
  85% {top: 50%; left: 50%; height: 80%;}
  100 {top: revert; left: revert; height: revert;}
}
</style>
