<template>
  <div id="confetti-container" ref="confettiContainer">
    <div
      class="confetti"
      ref="confetti"
      v-for="index in num"
      :key="index"
      :style="{
        backgroundColor: `rgb(
          ${Math.round(random(255))}, ${Math.round(random(255))}, ${Math.round(random(255))}
        )`,
      }"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfettiModal.vue",
  data() {
    return {
      vw: window.innerWidth,
      vh: window.innerHeight,
      num: Math.max(window.innerWidth / 20, 50),
    };
  },
  methods: {
    random(high) {
      return Math.random() * high;
    },
  },
  mounted() {
    // The lines with disabled eslint are using a CDN library
    let tl = new TimelineMax(); // eslint-disable-line

    tl.add("start");
    this.$refs.confetti.forEach((element) => {
      let dot = element,
          dotTL = new TimelineMax(); // eslint-disable-line

      // set each confetti piece
      dotTL.set(dot, {
        x: this.random(this.vw),
        y: this.random(-this.vh) - 10,
        scale: this.random(1) + 1,
        opacity: this.random(1),
        transformStyle: "preserve-3d",
      });

      tl.to(
        dot,
        6,
        {
          y: this.vh + 100,
        ease:Sine.easeInOut, // eslint-disable-line
          repeat: -1,
          opacity: this.random(2),
          delay: -6,
          scale: this.random(1) + 1,
        },
        this.random(6),
        "start"
      );

      tl.to(
        dot,
        2,
        {
          x: "+=" + this.random(100),
          repeat: -1,
          yoyo: true,
        ease:Sine.easeInOut // eslint-disable-line
        },
        this.random(2),
        "start"
      );

      tl.to(
        dot,
        this.random(4),
        {
          repeat: -1,
          yoyo: true,
          rotationX: this.random(270),
          rotationY: this.random(270),
        ease:Sine.easeInOut // eslint-disable-line
        },
        this.random(2),
        "start"
      );
    });
  }
};
</script>

<style scoped>
body {
  margin: 0;
}
#confetti-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
}
.confetti {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  z-index: 5;
}
</style>
